
import {createRouter, createWebHashHistory} from 'vue-router'
import MainView from "@/components/MainView.vue";

const routes=[
    {
        name: 'MainView',
        path: '/',
        component: MainView
    }
    //这里和vue2一样
/*    {
        path: '/about',
        component: About,
        // about组件里的嵌套路由
        children: [
            { path: 'tab1', component: Tab1 },
            { path: 'tab2', component: Tab2 }
        ]
    },*/
    // 路由重定向：强制跳转
   // { path: '/', redirect: '/home' }
]
const router = createRouter({
    history: createWebHashHistory(),
    routes
})

export default router


