<template>
    <div>
        <router-view></router-view>
    </div>
</template>

<script>

</script>

<style>
html, body, #app {
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    overflow: hidden;
    font-size: 15px;
    font-family: 'Arial', sans-serif;
}
</style>
